import React from 'react'
// import { graphql } from 'gatsby'
// import Img from 'gatsby-image'
import Layout from "../components/layout"

const About = () => (
  <Layout>
    <h1>About me</h1>
    <p>I’m good enough, I’m smart enough, and gosh darn it, people like me!</p>
  </Layout>
)

// const About = ({ data: { about } }) => (
//    <Layout>
//       <article className="sheet">
//          <HelmetDatoCms seo={about.seoMetaTags} />
//          <div className="sheet__inner">
//          <h1 className="sheet__title">{about.title}</h1>
//          <p className="sheet__lead">{about.subtitle}</p>
//          <div className="sheet__gallery">
//             <Img fluid={about.photo.fluid} />
//          </div>
//          <div
//             className="sheet__body"
//             dangerouslySetInnerHTML={{
//                __html: about.bioNode.childMarkdownRemark.html,
//             }}
//          />
//          </div>
//       </article>
//    </Layout>
// )

export default About

// export const query = graphql`
//   query AboutQuery {
//     about: datoCmsAboutPage {
//       seoMetaTags {
//         ...GatsbyDatoCmsSeoMetaTags
//       }
//       title
//       subtitle
//       photo {
//         fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
//           ...GatsbyDatoCmsSizes
//         }
//       }
//       bioNode {
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }
// `